import { Controller } from "stimulus"
import { nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["update"]
  static classes = ["loading"]

  showLoadingState(event) {
    const target = event.target.closest(this.updateTargetSelector)
    if (target) target.classList.add(this.loadingClass)
  }

  async replaceUpdateTargets(event) {
    const html = await event.detail.response.text()
    const replacements = this.parseUpdateTargets(html)
    await nextFrame()
    for (const [index, target] of this.updateTargets.entries()) {
      const replacement = replacements[index]
      if (!target.isEqualNode(replacement)) {
        target.replaceWith(replacement)
      }
    }
  }

  parseUpdateTargets(html) {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return [...doc.querySelectorAll(this.updateTargetSelector)]
  }

  get updateTargetSelector() {
    return `[data-${this.identifier}-target="update"]`
  }
}
