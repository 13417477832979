import { csrfToken, uniq } from "../helpers"

export class Reader {
  constructor(options = {}) {
    this.url = options.url
    this.sgids = new Set()
  }

  add(sgid) {
    if (this.has(sgid)) return
    this.sgids.add(sgid)
    this.scheduleFlush()
  }

  has(sgid) {
    return this.sgids.has(sgid) || this.storedSgids.has(sgid)
  }

  store(sgids) {
    this.storedSgids = uniq([...sgids, ...this.storedSgids])
  }

  scheduleFlush() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.flush, 3000)
  }

  flush = async () => {
    if (!this.sgids.size) return
    await fetch(this.url, this.options)
    this.store(this.sgids)
    this.sgids.clear()
  }

  get options() {
    return { method: "post", headers: this.headers, body: this.body }
  }

  get headers() {
    return { "Content-Type": "application/json", "X-CSRF-Token": csrfToken() }
  }

  get body() {
    return JSON.stringify({ sgids: [...this.sgids] })
  }

  get storedSgids() {
    return new Set(localStorage["reader:sgids"]?.split(","))
  }

  set storedSgids(sgids) {
    localStorage["reader:sgids"] = sgids.slice(0, 100).join(",")
  }
}
