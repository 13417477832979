import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    params: Object,
    afterCancelUrl: String,
    defaultMessageRequest: String,
  }

  initialize() {
    if (!this.formElement) throw Error("Controller must be placed on a form input")
  }

  // Actions

  intercept(event) {
    if (!window.churnkey) return
    event.preventDefault()
    window.churnkey.init("show", { ...this.churnkeyHandlers, ...this.paramsValue })
  }

  // Churnkey callbacks

  churnkeyHandlers = {
    handleSupportRequest: () => {
      window.Beacon("open")
      window.Beacon("navigate", "/ask/message/")
      window.Beacon("prefill", { text: this.defaultMessageRequestValue })
      window.churnkey.hide()
    },

    handleCancel: () => {
      return new Promise((resolve, reject) => {
        // Submit the form async to prevent navigating away
        const { action, method } = this.formElement
        const body = new FormData(this.formElement)
        fetch(action, { method, body }).then((resp) => {
          resp.ok ? resolve({}) : reject({})
        })
      })
    },

    onGoToAccount: ({ canceled }) => {
      if (canceled) {
        window.location = this.afterCancelUrlValue
      }
    },
  }

  // Private

  get formElement() {
    return this.element.form
  }
}
