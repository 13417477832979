import { Reader } from "../../lib/reader"

const reader = new Reader({ url: "/bulk/read_receipts" })

const observer = new IntersectionObserver((entries) => {
  for (const { isIntersecting, target } of entries) {
    if (isIntersecting) target.intersectedCallback()
  }
})

class ReadReceiptElement extends HTMLElement {
  connectedCallback() {
    observer.observe(this)
  }

  intersectedCallback() {
    reader.add(this.sgid)
    this.remove()
  }

  disconnectedCallback() {
    observer.unobserve(this)
  }

  get sgid() {
    return this.getAttribute("sgid")
  }
}

customElements.define("read-receipt", ReadReceiptElement)
