import { domReady } from "../helpers"

domReady(function () {
  const elements = document.querySelectorAll('[data-helpscout="beacon"]')
  if (elements.length === 0) return

  elements.forEach((el) => {
    el.addEventListener("click", function (e) {
      e.preventDefault()
      if (window.Beacon) {
        window.Beacon("open")
      }
    })
  })
})
